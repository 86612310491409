exports.components = {
  "component---src-pages-components-card-js": () => import("./../../../src/pages/components/card.js" /* webpackChunkName: "component---src-pages-components-card-js" */),
  "component---src-pages-components-navbar-js": () => import("./../../../src/pages/components/navbar.js" /* webpackChunkName: "component---src-pages-components-navbar-js" */),
  "component---src-pages-hero-hero-js": () => import("./../../../src/pages/hero/hero.js" /* webpackChunkName: "component---src-pages-hero-hero-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-layout-js": () => import("./../../../src/pages/layout.js" /* webpackChunkName: "component---src-pages-layout-js" */),
  "component---src-pages-markdown-remark-frontmatter-slug-js": () => import("./../../../src/pages/{MarkdownRemark.frontmatter__slug}.js" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-slug-js" */),
  "component---src-pages-sections-aiming-high-js": () => import("./../../../src/pages/sections/aiming-high.js" /* webpackChunkName: "component---src-pages-sections-aiming-high-js" */),
  "component---src-pages-sections-descriptor-js": () => import("./../../../src/pages/sections/descriptor.js" /* webpackChunkName: "component---src-pages-sections-descriptor-js" */),
  "component---src-pages-sections-features-js": () => import("./../../../src/pages/sections/features.js" /* webpackChunkName: "component---src-pages-sections-features-js" */),
  "component---src-pages-sections-footer-js": () => import("./../../../src/pages/sections/footer.js" /* webpackChunkName: "component---src-pages-sections-footer-js" */),
  "component---src-pages-sections-more-info-js": () => import("./../../../src/pages/sections/more-info.js" /* webpackChunkName: "component---src-pages-sections-more-info-js" */),
  "component---src-pages-sections-scopes-js": () => import("./../../../src/pages/sections/scopes.js" /* webpackChunkName: "component---src-pages-sections-scopes-js" */),
  "component---src-pages-sections-tech-stack-js": () => import("./../../../src/pages/sections/tech-stack.js" /* webpackChunkName: "component---src-pages-sections-tech-stack-js" */)
}

